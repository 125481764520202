<template>

  <div>
    <!-- Filters -->
    <list-filters
      :localData="localData"
      :load-filter.sync="loadFilter"
      @emitFilterData="emitFilterData"
    />
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import {
  getJenisUPT,
  getKanwil,
  getUPT,
  getJenisAsset,
  getStatusAsset,
  deleteJenisDokumen,
  exportLaporan,
} from '@/connection-api/master'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { avatarText } from '@core/utils/filter'
import storeModule from '@/connection-api/laporan-periodik/storeModule'
import useList from '@/connection-api/laporan-periodik/list'
import listAddNew from './ListAddNew.vue'
import listFilters from './ListFilters.vue'

export default {
  components: {
    listFilters,
    // listAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    // BLink,
    BDropdown,
    BDropdownItem,
    BPagination,
    BBadge,
    BSpinner,

    vSelect,
  },
  data() {
    return {
      variants: ['primary', 'secondary', 'danger', 'warning', 'success', 'info', 'primary'],
      showLoader: false,
      localData: {
        tanggal_awal: null,
        tanggal_akhir: null,
      },
    }
  },
  methods: {
    async downloadLaporan() {
      this.showLoader = true
      const response = await exportLaporan(this.localData.tanggal_awal, this.localData.tanggal_akhir)
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'Laporan Keuangan.xlsx')
      document.body.appendChild(link)
      link.click()
      this.showLoader = false
    },
    emitFilterData (data) {
      this.dataFilter = data
    },
    async deleteData(id){
      if (confirm('Anda yakin ingin menghapus data ini?')) {
        // Save it!
        const response = await deleteJenisDokumen(id)
        if(response.data.status === 'success'){
          this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Jenis Dokumen',
                icon: 'CoffeeIcon',
                variant: 'success',
                text: 'Data berhasil di hapus',
              },
            })
            this.$router.go('/')
        }else{
          this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Jenis Dokumen',
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: 'Data gagal di hapus',
              },
            })
        }
      }
    },
    emitAddSuccess(assetId) {
      this.$router.replace(`/jenis/dokumen/edit/${assetId}`)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Jenis Dokumen',
              icon: 'CoffeeIcon',
              variant: 'success',
              text: 'Data berhasil di tambahkan',
            },
          })
        })
    },
    async reloadUPTOptions(kanwilId) {
      this.uptFilter = null
      this.uptOptions = {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih UPT --' },
        ],
      }
      const responseUptOptions = await getUPT(kanwilId)
      this.mappingSelectOption(responseUptOptions.data, this.uptOptions.options)
    },
    mappingSelectOption(master, name) {
      if (master.length > 0) {
        master.forEach(element => {
          name.push({
            label: element.nama,
            value: element.id,
          })
        })
      }
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'laporan-periodik'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const isAddNewUserSidebarActive = ref(false)

    const {
      fetchDatas,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      loadFilter,
      dataFilter,
    } = useList()

    return {

      // Sidebar
      isAddNewUserSidebarActive,

      fetchDatas,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      loadFilter,
      dataFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
